<template>
  <div class="list-content">
    <div style="padding: 10px; border: 1px solid #218da0">
      <el-input v-model="query.hd_id" size="mini" style="width: 180px" placeholder="海鼎ID" />
      <el-date-picker style="margin-left: 10px" v-model="query.date" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      &nbsp;&nbsp;&nbsp;
      客服:
      <el-select v-model="query.sid" placeholder="请选择" size="mini">
        <el-option v-for="item in csOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-button size="mini" style="margin-left:10px" type="primary" @click="queryData">查 询</el-button>
      <el-button size="mini" type="danger" @click="exportOrder">导 出</el-button>
    </div>

    <div class="bodyPage_body" style="border: 1px solid #218da0; padding: 2px">
      <el-table :data="tableData" border style="width: 100%" height="660" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" width="40" align="center"></el-table-column>
        <el-table-column prop="order_sn" label="订单号码	" width="180" align="center">
          <template slot-scope="{row}">
            {{row.order_sn}}
            <span v-if="row.split_order_id > 0" class="splitSpan">拆</span>
            <div v-if="row.split_order_id > 0">拆于订单ID: <span style="color:red;margin-left:10px">{{ row.split_order_id }}</span></div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_number" label="商品总数量" width="120" align="center"></el-table-column>
        <el-table-column prop="goods_list" label="涉及商品条数" width="120" align="center"></el-table-column>
        <el-table-column prop="total" label="总价" width="160" align="center">
          <template v-slot="{ row }">
            <p>总价： {{ row.total }}</p>
            <p style="color: #01a3a3">成交： {{ row.make_total }}</p>
          </template>
          <template slot="header" slot-scope="{ column, $index }">
            总价<br />
            <span style="color: red">总额：￥{{ thePageOrderTotal.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hd_id" label="海鼎ID" width="90" align="center"></el-table-column>
        <el-table-column prop="buyer" label="购买者用户名" width="180" align="center"></el-table-column>

        <el-table-column prop="buyer" label="国家" width="180" align="center">
          <template v-slot="{ row }">
            {{ countryKey2Name[allUserCountry[row.user_id]] }}
          </template>
        </el-table-column>
        <el-table-column prop="buyer" label="客服" width="180" align="center">
          <template v-slot="{row}">
            {{ admins[adminData[row.user_id]] }}
          </template>
        </el-table-column>
        <el-table-column prop="buyer" label="创建时间" width="150" align="center">
          <template slot-scope="{ row }">
            {{ row.created_at | formatDateStr }}
          </template>
        </el-table-column>

        <el-table-column prop="buyer" label="状态" width="150" align="center">
          <template v-slot="{ row }">
            <p :class="`setOrderPrce${row.set_30_status}`">30%扣款:{{ priceStatus[row.set_30_status] }}</p>
            <p :class="`setOrderPrce${row.set_70_status}`">70%扣款:{{ priceStatus[row.set_70_status] }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="buyer" label="操作" align="left" width="150">
          <template v-slot="{ row }">
            <span>
              <el-button class="margin5" v-if="row.set_30_status === 0" @click="setStatus30(row)" type="primary" size="mini"> 确认30%预扣款
              </el-button>
              <el-button class="margin5" style="margin-left: 0" v-if="row.set_70_status === 0" @click="setStatus70(row)" type="success" size="mini"> 扣取70%预扣款
              </el-button>
              <el-button v-if="row.set_30_status == 1 && row.set_70_status == 1 && row.hd_status == 0" class="margin5" style="margin-left: 0" type="danger" size="mini" @click="submitHd(row)">
                提交海鼎
              </el-button>
            </span>
            <div>
              <el-button type="warning" size="mini" @click="showInfo(row)">详情</el-button>
              <el-button size="mini" @click="delOrderRow(row)">删除</el-button>
            </div>
            <div style="margin-top:4px;">
              <el-button size="mini" type="danger" @click="splitOrder(row.id)">拆分订单</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination style="padding-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pagesData.page" :page-sizes="[20, 50, 80, 100, 200]" :page-size="pagesData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
    </el-pagination>

    <el-drawer :with-header="false" size="70%" title="我是标题" :visible.sync="orderInformation.drawer" :direction="orderInformation.direction" :before-close="handleClose">
      <div class="showInfoTitle">订单详情</div>

      <div class="tableContent" style="padding: 10px">
        <p>订单号：{{ orderInformation.orderInfo.order_sn }}</p>
        <p>订单总价：￥{{ orderInformation.orderInfo.total }}</p>
        <p>成交订单：￥{{ orderInformation.orderInfo.make_total }}</p>
      </div>

      <div class="tableContent">
        成功商品：
        <el-table :data="okTableData" style="width: 100%" max-height="340" border>
          <!-- <el-table-column fixed prop="id" label="ID" width="100" align="center"></el-table-column> -->
          <el-table-column prop="hd_id" label="海鼎ID" width="60" align="center"></el-table-column>
          <el-table-column prop="buyer" label="用户名" width="70" align="center"></el-table-column>
          <el-table-column prop="Sku" label="商品编码" align="center"></el-table-column>
          <el-table-column prop="hd_sku" label="海鼎SKU" align="center"></el-table-column>
          <el-table-column prop="item_name" label="商品名称" width="180" align="center"></el-table-column>
          <el-table-column prop="spec" label="规格" align="center"></el-table-column>
          <el-table-column prop="goods_price" label="商品单价" align="center"></el-table-column>
          <el-table-column prop="goods_number" label="商品数量" align="center" width="220">
            <template v-slot="{row}">
              <el-input-number v-model="row.goods_number" :min="row.spec" :step="row.spec" size="mini" @change="(currentValue, oldValue) =>handleChange(currentValue, oldValue, row)">
              </el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="goods_total" label="商品总价" align="center"></el-table-column>
          <el-table-column prop="qty" label="到货数" align="center" width="60"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center" width="160"></el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button @click="deleteRowGoods(row)" type="text" size="small"> 移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="tableContent">
        全部商品：
        <el-table :data="orderInformation.tableData" style="width: 100%" max-height="340" border>
          <!-- <el-table-column fixed prop="id" label="ID" width="50" align="center"></el-table-column> -->
          <el-table-column prop="hd_id" label="海鼎ID" width="120" align="center"></el-table-column>
          <el-table-column prop="buyer" label="用户名" width="120" align="center"></el-table-column>
          <el-table-column prop="Sku" label="商品编码" align="center"></el-table-column>
          <el-table-column prop="hd_sku" label="海鼎SKU" align="center"></el-table-column>
          <el-table-column prop="item_name" label="商品名称" width="220" align="center"></el-table-column>
          <el-table-column prop="spec" label="规格" align="center"></el-table-column>
          <el-table-column prop="goods_price" label="商品单价" align="center"></el-table-column>
          <el-table-column prop="goods_number" label="商品数量" align="center"></el-table-column>
          <el-table-column prop="goods_total" label="商品总价" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center" width="160"></el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button @click="deleteRowGoods(row)" type="text" size="small"> 移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import common from "@/common/mixins/common.js";
import countryAPI from "@/api/admin/country";
import userAPI from "@/api/admin/user";

export default {
  mixins: [common],
  data() {
    return {
      allUserCountry: [],
      countryKey2Name: [],
      orderStatus: {
        0: "待审核",
        1: "审核通过",
        2: "审核不通过",
      },
      tableData: [],
      okTableData: [],
      thePageOrderTotal: 0,
      pagesData: {
        page: 1,
        pageSize: 20,
        total: 100,
      },
      query: {
        hd_id: "",
        date: "",
        sid: "",
      },
      orderInformation: {
        data: {},
        drawer: false,
        direction: "rtl",
        tableData: [],
        orderInfo: {},
      },
      priceStatus: {
        1: "已扣款",
        0: "未扣款",
      },
      selOrder: [], //选中订单,
      admins: [],
      adminData: [],
      csOptions: [
        {
          value: "10",
          label: "何虹均",
          disabled: true,
        },
        {
          value: "4",
          label: "戴冰冰",
          disabled: true,
        },
        {
          value: "5",
          label: "刘春燕",
          disabled: true,
        },
        {
          value: "7",
          label: "唐珊",
          disabled: true,
        },
        {
          value: "8",
          label: "冯韫琪",
          disabled: true,
        },
        {
          value: "20",
          label: "林莹",
          disabled: true,
        },
        {
          value: "38",
          label: "周绮莉",
          disabled: true,
        },
      ],
    };
  },
  created() {
    //获取数据
    this.getData();
    this.getCountry();
    this.handleAdmin();
  },
  methods: {
    getCountry() {
      //生成国家 key => 名字
      countryAPI.getKeyCountry().then((res) => {
        let resData = res.data.data;
        let countryList = [];
        for (let index = 0; index < resData.length; index++) {
          countryList[resData[index].code] = resData[index].name;
        }
        this.countryKey2Name = countryList;
      });

      //生成用户国际
      userAPI.getAllUsers().then((res) => {
        let resUserIdData = [];
        let adminData = [];
        let resData = res.data.data;
        for (let index = 0; index < resData.length; index++) {
          resUserIdData[resData[index].id] = resData[index].country;
          adminData[resData[index].id] = resData[index].cs_id;
        }
        this.allUserCountry = resUserIdData;
        this.adminData = adminData;
      });
    },
    handleSelectionChange(val) {
      this.selOrder = val;
    },
    //导出预售订单
    exportOrder() {
      //console.log(this.selOrder)
      let postOrder = [];
      for (let key in this.selOrder) {
        postOrder.push(this.selOrder[key].id);
      }
      if (postOrder.length > 0) {
        let postData = {
          order_id: postOrder,
        };
        //创建订单商品导出
        this.$axios.post("/a1/presell/order/CreateExpPreOrderGoods", postData).then((res) => {
          let dowloadKey = res.data.data.to_key;
          //开始下载
          location.href = this.$root.DownUrl + "/c1/down/" + dowloadKey;
        });
      } else {
        this.$message.error("请选中要导出的订单");
      }
    },
    submitHd(row) {
      if (confirm("确认提交海鼎吗?")) {
        let orderSN = row.order_sn;
        this.$axios
          .post(`/a1/presell/Submit2HaiDing/${orderSN}`)
          .then(() => {
            this.$message.success("提交成功");
          })
          .catch(() => {
            this.$message.error("提交失败");
          });
      }
    },
    //设置30%扣款
    setStatus30(row) {
      let orderId = row.id;
      this.$axios.put(`/a1/presell/setOrder/Status30/${orderId}`).then(() => {
        this.$message.success("设置成功");
        this.getData();
      });
    },
    //设置70%扣款
    setStatus70(row) {
      let orderId = row.id;
      this.$axios.put(`/a1/presell/setOrder/Status70/${orderId}`).then(() => {
        this.$message.success("设置成功");
        this.getData();
      });
    },
    //删除订单一个商品
    deleteRowGoods(row) {
      if (confirm("你确认删除该订单的商品吗？")) {
        let orderGoodsId = row.id;
        this.$axios.delete(`/a1/presell/delOrderGoods/${orderGoodsId}`).then(() => {
          this.$message.success("操作成功");
          this.showInfo(this.orderInformation.orderInfo);
        });
      }
    },
    //删除一个订单
    delOrderRow(row) {
      if (confirm("你确认删除该预售订单吗？")) {
        let order_sn = row.order_sn;
        this.$axios.delete(`/a1/presell/delOrder/${order_sn}`).then(() => {
          this.$message.success("操作成功");
          this.getData();
        });
      }
    },
    // 拆分订单(将已经到货的订单拆分出来)
    splitOrder(id) {
      this.$axios
        .post(`/a1/presell/splitOrder/${id}`)
        .then(() => {
          this.$message.success("拆分成功");
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    //查询数据
    queryData() {
      this.getData();
    },

    //打开预售订单详情列表
    showInfo(row) {
      //this.showRow = row
      this.orderInformation.orderInfo = row;
      this.orderInformation.drawer = true;
      this.$axios.get("/a1/presell/getOrderGoods/" + row.order_sn).then((res) => {
        this.orderInformation.tableData = res.data.data;
        let okTableData = [];
        for (let key in res.data.data) {
          if (res.data.data[key].order_status === 1) {
            okTableData.push(res.data.data[key]);
          }
        }
        this.okTableData = okTableData;
      });
    },
    //关闭
    handleClose(done) {
      done();
    },
    //获取数据
    getData() {
      let queryData = {
        page: this.pagesData.page,
        page_size: this.pagesData.pageSize,
      };
      if (this.query.hd_id > 0) {
        queryData.hd_id = this.query.hd_id;
      }
      if (this.query.sid > 0) {
        queryData.sid = this.query.sid;
      }
      if (this.query.date.length > 0) {
        queryData.begin_time = this.query.date[0] / 1000;
        queryData.end_time = this.query.date[1] / 1000;
      }
      this.$axios
        .get("/a1/presell/order", {
          params: queryData,
        })
        .then((res) => {
          let resData = res.data.data;
          this.tableData = resData.data;
          //计算当前页订单总价 begin
          let toTal = 0;
          for (let key in this.tableData) {
            toTal += this.tableData[key].total;
          }
          this.thePageOrderTotal = toTal;
          //计算当前页订单总价 end
          this.pagesData.page = resData.page;
          this.pagesData.pageSize = resData.page_size;
          this.pagesData.total = resData.total;
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.pagesData.pageSize = val;
      this.getData();
    },
    handleCurrentChange() {
      this.getData();
    },
    handleChange(currentValue, oldValue, rowData) {
      let putData = {
        order_goods_id: rowData.id,
        num: currentValue,
      };
      if (currentValue % rowData.spec > 0) {
        putData.num = oldValue;
      } else {
        putData.num = currentValue;
      }
      //修改订单商品数量
      this.$axios.put("/a1/presell/setGoodsNumber", putData).then((res) => {
        console.log(res);
        this.showInfo(this.orderInformation.orderInfo);
      });
    },
    handleAdmin() {
      this.$axios.get("/a1/admin/all").then((res) => {
        const { data } = res.data;
        for (let index = 0; index < data.length; index++) {
          this.admins[data[index].id] = data[index].user_name;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.bodyPage {
  // border: 1px solid red;
  &_nav {
    border-bottom: 1px solid #ccc;
    height: 35px;
    line-height: 35px;
    text-indent: 1em;
  }

  &_body {
    // border: 1px solid blue;
  }
}

//详情显示
.showInfoTitle {
  background: #218da0;
  color: white;
  padding: 10px;
}

.tableContent {
  border: 1px solid #218da0;
  margin: 5px;
}

.setOrderPrce1 {
  color: red;
}

.margin5 {
  margin: 5px 0;
}
.splitSpan{
  border:1px solid #218da0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: red;
}
</style>
