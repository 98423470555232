<template>
  <div class="bodyPage">

    <el-tabs type="border-card" style="height:100%">
      <el-tab-pane label="预售订单">
        <List></List>
      </el-tab-pane>
      <el-tab-pane label="选购记录">
        <Log></Log>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import common from "@/common/mixins/common.js";
import List from "./list";
import Log from "./log";

export default {
  mixins: [common],
  components: {
    List,
    Log,
  },
  data() {
    return {};
  },
  created() {
    //获取数据
  },
  methods: {},
};
</script>

<style lang="scss">
.bodyPage {
  // border: 1px solid red;
  &_nav {
    border-bottom: 1px solid #ccc;
    height: 35px;
    line-height: 35px;
    text-indent: 1em;
  }

  &_body {
    // border: 1px solid blue;
  }
}

//详情显示
.showInfoTitle {
  background: #218da0;
  color: white;
  padding: 10px;
}

.tableContent {
  border: 1px solid #218da0;
  margin: 5px;
}

.setOrderPrce1 {
  color: red;
}

.margin5 {
  margin: 5px 0;
}
</style>
