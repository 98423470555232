<template>
  <div class="log-content">

    <div style="padding: 10px;border-bottom:  1px solid rgba(153,153,153,0.56)">
      <el-input v-model="query.hdid" size="mini" style="width: 180px" placeholder="海鼎ID" clearable />
      <el-date-picker
        style="margin-left: 10px"
        v-model="query.date"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-button size="mini" style="margin-left:10px" type="primary" @click="handleQuery">查 询</el-button>
    </div>

    <el-table :data="logData" width="100%" height="660">
      <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
      <el-table-column prop="sku" label="sku" align="center"></el-table-column>
<!--      <el-table-column prop="hdId" label="商户名称" align="center"></el-table-column>-->
      <el-table-column prop="hdId" label="海鼎编号" align="center"></el-table-column>
      <el-table-column prop="order_sn" label="操作类型" align="center">
        <template v-slot="{row}">
          {{ row.action === 1 ? "添加" : "删除" }}
        </template>
      </el-table-column>
      <el-table-column prop="numbers" label="操作数量" align="center"></el-table-column>
      <el-table-column prop="createTime" label="操作时间" align="center"></el-table-column>
    </el-table>

    <el-pagination
      style="padding-top: 15px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pagesData.page"
      :page-sizes="[20, 50, 80, 100, 200]"
      :page-size="pagesData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagesData.total">
    </el-pagination>

  </div>
</template>

<script>

import { getPresellGoodsLog } from "@/api/admin/presell";

export default {
  name: "log",
  data() {
    return {
      query: {
        hd_id: "",
        date: [],
      },
      logData: [],
      pagesData: {
        page: 1,
        pagesize: 20,
        total: 0,
      },
    };
  },
  created() {
    this.handleGetLog();
  },
  methods: {
    handleGetLog() {

      let query={
        page: this.pagesData.page,
        pagesize: this.pagesData.pagesize,
      };

      if (this.query.hdid) query.hdId=this.query.hdid;
      if (this.query.date.length > 0) query.date=this.query.date;

      getPresellGoodsLog(query).then(res => {
        const { data }=res.data;
        this.logData=data.data;
        this.pagesData.total=res.data.data.total || 0;

      });
    },
    handleQuery() {

      this.pagesData.page=1;

      this.handleGetLog();

    },
    handleSizeChange(val) {
      this.pagesData.pagesize=val;
      this.handleGetLog();
    },
    handleCurrentChange(val) {
      this.pagesData.page=val;
      this.handleGetLog();
    },
  },
};
</script>

<style scoped>

</style>
